.theme-toggler {
  padding-right:20px;
  height: 24px;
}

.toggle-icon {
   font-size: 17px;
   height: 10px;
   filter: contrast(150%);
   justify-content: center;
   align-items: center;
   display: flex;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4d4d4d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #4d4d4d;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #4d4d4d;
}

.react-toggle .react-toggle-track {
  background-color: #4d4d4d;
}
