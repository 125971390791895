#header {
  background-color: #161b22;
  position: fixed; /* Set the navbar to fixed position */
  z-index: 999;
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}

.menu-row {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0;
}

button {
  margin-left: 4px;
}

#collapse {
  background-color: #161b22;
  width: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
}

.collapse-isOpen-true {
  background-color: #161b22;
  width: 100%;
  height: 56px;
  align-items: center;
  display: flex;
  position: fixed;
}

@media only screen and (min-width: 575px) {
  .collapse-isOpen-true {
    display: none;
  }
}

.collapse-isOpen-false {
  padding-top: 0px;
  background-color: #161b22;
  width: 100%;
  align-items: center;
  display: flex;
}

#collapse input {
  padding: 0;
  margin: 0;
  max-width: 200px;
  flex: auto;
  align-items: center;
}
