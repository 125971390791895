.difficulty-unavailable-circle {
  border-color: #000000;
  background: #000000;
}

.common-difficulty-circle {
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
}

.big-difficulty-circle {
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  width: 36px;
  height: 36px;
}
