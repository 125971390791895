.OK {
  background: #004306 !important;
}

.NG {
  background: #52212b !important;
}

body {
  color: #c9d1d9;
  background: #090c10;
}

h2,
h3,
h4,
h6 {
  color: white;
}

table {
  color: #c9d1d9;
  background: #090c10;
  border: none !important;
  border-radius: 0px;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none !important;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border: 1px solid #484848;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table 
  > thead
  > tr
  > th {
  color: #c9d1d9;
  background: #090c10;
  border: 1px solid #484848;
}

.ant-pagination-item {
  a {
    color: #c9d1d9;
  }
  background: #090c10;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #c9d1d9;
  border-color: #d9d9d9;
  background: #090c10;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  color: #c9d1d9;
  border-color: #d9d9d9;
  background: #090c10;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #c9d1d9;
  background: #090c10;
}
.ant-select-dropdown {
  color: #c9d1d9;
  background: #090c10;
}
.ant-select-dropdown:hover {
  color: #c9d1d9;
  background: #090c10;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #c9d1d9;
  background: #090c10;
}
.ant-select-item-option-active .ant-select-item-option-selected {
  color: #c9d1d9;
  background: #090c10;
}
.rc-virtual-list-holder-inner {
  color: #c9d1d9;
  background: #090c10;
}
.ant-select .ant-select-selector {
  color: #c9d1d9;
  background: #090c10;
}

.ant-select-item {
  color: #c9d1d9;
  background: #090c10;
}

.ant-select-item-option {
  color: #c9d1d9;
  background: #090c10;
}
.ant-select-item-option-selected {
  color: #c9d1d9;
  background: #090c10;
}

.ant-radio-button-wrapper {
  color: #c9d1d9;
  background: #21262d;
}

.ant-radio-button-wrapper:hover {
  color: #c9d1d9;
}

.difficulty-unavailable-circle {
  border-color: #ffffff;
  background: #ffffff;
}

.difficulty-black:not(:hover) {
  color: #ffffff;
}

.difficulty-grey:not(:hover) {
  color: #c0c0c0;
}

.difficulty-green:not(:hover) {
  color: #3faf3f;
}

.difficulty-cyan:not(:hover) {
  color: #42e0e0;
}

.difficulty-blue:not(:hover) {
  color: #8888ff;
}

.difficulty-violet:not(:hover) {
  color: #ba55d3;
}

.difficulty-red:not(:hover) {
  color: #ff375f;
}

.ant-switch {
  background: #ffffff;
}

.ant-switch-handle::before {
  color: #888888;
  background: #888888;
}

.ant-switch-checked {
  background: #0e7aac;
}

.ant-switch-checked .ant-switch-handle::before {
  color: white;
  background: white;
}

.ant-pagination-item-ellipsis {
  background: #21262d;
  color: black;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  background: #090c10;
}

td .table-not-success-1 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-2 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-3 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-4 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-5 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-6 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-7 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-8 {
  border-bottom: 1px solid #484848;
}
td .table-not-success-9 {
  border-bottom: 1px solid #484848;
}
td .table-notsuccess-10 {
  border-bottom: 1px solid #484848;
}

.ant-radio-button-wrapper:hover {
  background: #1890ff;
  background: #0e7aac;
}

.verdict-rejected {
  color: #0e7aac;
  color: #8888ff;
}
