#input-box {
  position: relative;
  display: flex;
  flex: auto;
  align-items: center;
  margin: 0 auto 0 0;
  padding-left: 16px;
  padding-right: 16px;
  white-space: nowrap;
}

#input-box input {
  width: 100%;
  max-width: 200px;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: white;
}

#input-box button {
  padding: 0 0 0 0;
  margin-right: 15px;
  margin-left: 5px;
  text-align: center;
  height: 40px;
  width: 100%;
  font-weight: 450;
  max-width: 56px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #484f58;
  border-color: #484f58;
  color: #c9d1d9;
}
