.OK {
  background: #c3e6cb !important;
}

.NG {
  background: #ffe3e3 !important;
}

.cell-height-1 {
  height: 100%;
  width: 100%;
}

.cell-height-2 {
  height: 50%;
  width: 100%;
}

.cell-height-3 {
  height: 33.333%;
  width: 100%;
}

.cell-height-4 {
  height: 25%;
  width: 100%;
}

.cell-height-5 {
  height: 20%;
  width: 100%;
}

.cell-height-6 {
  height: 16.666%;
  width: 100%;
}

.cell-height-7 {
  height: 14.286%;
  width: 100%;
}

.cell-height-8 {
  height: 12.5%;
  width: 100%;
}

.cell-height-9 {
  height: 11.111%;
  width: 100%;
}

.cell-height-10 {
  height: 10%;
  width: 100%;
}

.difficulty-grey {
  color: #808080;
}
.difficulty-green {
  color: #008000;
}
.difficulty-cyan {
  color: #03a89e;
}
.difficulty-blue {
  color: #0000ff;
}
.difficulty-violet {
  color: #aa00aa;
}
.difficulty-orange {
  color: #ff8c00;
}
.difficulty-red {
  color: #ff0000;
}
.difficulty-black {
  color: #000000;
}

tr {
  line-height: 1.5;
}

.inline-btn {
  display: inline-block;
  margin-right: 15px;
  font-size: 16px;
}

.ant-switch {
  margin-right: 5px;
}

.custom-control-label {
  position: relative;
  display: block;
}

#radio-buttons {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  font-weight: bold;
  font-size: 16px;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  /* padding: 12px 12px 12px 12px; */
  padding: 0 0 0 0;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  .cell-element {
  padding: 12px 12px 12px 12px;
  flex: auto;
}

#contest-table {
  margin-top: 1.5rem;
}

@media only screen and (max-width: 575px) {
  .ant-contest-table thead tr {
    display: none;
  }

  .ant-table-cell:empty {
    display: none;
  }

  .ant-contest-table td {
    display: block;
  }

  .ant-contest-table
    .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td:not(:first-child)
    .cell-element {
    padding-left: 2.5rem;
  }

  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    height: auto !important;
  }

  .cell-element {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
}

table {
  height: 1px;
  width: 1px;
  border: 1px solid #dee2e6;
}

td {
  height: 100%;
}

h2 {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
}
